<template>
  <div class="appBox">
    <h3>应用权限</h3>
    <div class="line"></div>
    <ul>
      <li>
        为了向您提供服务，维护红狮集团产品及服务的正常运行，改进及优化我们的服务体验并保障您的账号安全，我们会出于本政策下述目的及方式收集您在注册、使用产品及服务时主动提供、授权提供或基于您的要求提供的信息，以及基于您使用红狮集团服务时产生的信息:
      </li>
      <li>
        (1) <span>*手机号码</span> ：当您使用手机号码及验证码注册红狮集团时，我们会收集您的手机号码。手机号码属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。
      </li>
      <li>
        (2) <span>*地理位置</span> ：为保证您能正常领取和使用有关行情权益及服务，我们会对您提供的红狮集团号、IP地址、第三方账号和头像、昵称等用以进行地域或账户有效性识别。如您不同意上述内容，可能会影响地域或账户的有效性判断，进而影响到您领取和使用有关行情权益及服务。
      </li>
      <li>
        (3) <span>*用户姓名</span> ：确保用户的资金操作是本人。
      </li>
      <li>
        (4) <span>*相机/相册</span> ：当您通过红狮集团接入完善账户功能时，红狮集团会使用设备的摄像头权限，拒绝使用摄像头权限，完善账户流程将无法正常完成。根据法律要求，在您通过红狮集团申请开户时，必须提交身份证正反面照片、银行卡照片等信息。若您不同意上述内容，您将无法使用开户功能，但不影响您使用红狮集团的其他服务。
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name:'Application',
  created(){
    // 传参控制显示顶部和底部
    this.$emit('controlShow', false);
  }
}
</script>

<style lang="less" scoped>
  .appBox{
    width: 1138px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 50px 0 110px;
    h3{
      font-size: 24px;
    }
    .line{
      width: 100%;
      height: 1px;
      background-color: #eaeaea;
      margin: 40px auto 60px;
    }
    ul{
      li{
        line-height: 25px;
        letter-spacing: 1px;
        margin-bottom: 40px;
        color: #6d6d6d;
        span{
          color: #fe0100;
        }
      }
    }
  }
</style>